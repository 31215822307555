import { $themeBreakpoints } from '@themeConfig';
import axios from '@/libs/axios.js'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    isValidAccessToken: false,
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    HANDLE_ACCESS_TOKEN(state, val) {
      state.isValidAccessToken = val
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    accessTokenHandler ({commit}) {
      let accessToken = localStorage.getItem('accessToken');
      let user = localStorage.getItem('userData')
      return new Promise((resolve, reject) => {
        axios.get('/user?method=select-one&slug='+user.slug, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        })
          .then((response) => {
            console.log(response)
            if(response.status === 200) {
              commit('HANDLE_ACCESS_TOKEN', true);
             resolve(true)
            } else {
              commit('HANDLE_ACCESS_TOKEN', false);
              // Remove userData from localStorage
              // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
              localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
              localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

              // Remove userData from localStorage
              localStorage.removeItem('userData')

              // Reset ability
              this.$ability.update(initialAbility)

              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            }
          })
          .catch((error) => {
            commit('HANDLE_ACCESS_TOKEN', true);
            console.log('ERROR::', error)
            return false
          })
      })
    },
  },
}

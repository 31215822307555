import moment from 'moment';
import axios from '@/libs/axios.js'

moment.locale('fr');

export default {
  getNow: function() {
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return date + ' ' + time;
  },
  getTime: function() {
    let now = this.getNow();
    return now.split(" ")[1]
  },
  getHour: function() {
    let now = this.getTime();
    return now.split(":")[0]
  },
  buildGreeting: function () {
    if(this.getHour() >= 0 && this.getHour() <= 12) {
      return 'Bonjour'
    } else if(this.getHour() > 12 && this.getHour() < 18) {
      return 'Bon après-midi'
    } else if(this.getHour() >= 18 && this.getHour() <= 23) {
      return 'Bonsoir'
    } else {
      return 'Bienvenue'
    }
  },
  isEmptyObject: function (obj) {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return false;
      }
    }
    return true;
  },
  isEmptyArray: function (array) {
    return (!(array === undefined || array.length === 0));
  },
  dateOnlyFormat: function (date) {
    date = date.split("T");
    return date[0];
  },
  cdgDateFormat: (date, hour = false) => {
    if(date === '' || date === null) {
      return date
    } else {
      if(hour) {
        return moment(String(date)).format('DD/MM/YYYY à HH:MM:SS')
      } else {
        return moment(String(date)).format('DD/MM/YYYY')
      }
    }
  },
  inArray: function (needle, haystack) {
    let length = haystack.length;
    for (let i = 0; i < length; i++) {
      if (haystack[i] === needle) return true
    }
    return false
  },

  date: {
    today: (getString = false) => {
      const today = new Date()

      let date = ''
      if(getString) {
        date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      } else {
        date = today
      }

      return date 
    },
    yesterday: (count, getString = false) => {
      count = (count === null || count === undefined ? 1 : count)
      const today = new Date()
      today.setDate(today.getDate() - count);

      let date = ''
      if(getString) {
        date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      } else {
        date = today
      }

      return date
    },
  },
  object: {
    is_empty: function (obj) {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          return false;
        }
      }
      return true;
    },
    addOrUpdateObject(arr, obj) {
      const index = arr.findIndex(item => item.subject === obj.subject);

      if (index !== -1) {
        arr.splice(index, 1, obj);
      } else {
        arr.push(obj);
      }

      return arr
    }
  },
  string: {
    is_empty: function (value) {
      return(value === undefined || value === null || value.length<= 0 || value === '');
    }, 
    is_email: function(value) {
        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return regexp.test(value);
    }, 
    is_number: function(value) {
        return /^(?:-?\d+|-?\d{1, 3}(?:, \d{3})+)?(?:\.\d+)?$/.test(value);
    }, 
    is_date: function(value) {
        if (Object.prototype.toString.call(value) === "[object Date]") {
            return !isNaN(value.getTime());
        } else {
            return false
        }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  
  stringToArray(string) {
    if(typeof string === 'string') {
      return (string === null || string === '' ? [] : string.split(','))
    }
    return string
  },
  buildPdf(base64) {
    if(base64.length <= 0 || base64 === '') return base64;

    return 'data:application/pdf;base64,' + base64;
  },
  buildYoutubeLink(videoId) {
    if(videoId.length <= 0 || videoId === '') return videoId;

    return 'https://www.youtube.com/watch?v=' + videoId;
  },
  accessTokenHandler () {
    let accessToken = localStorage.getItem('accessToken');
    return new Promise((resolve, reject) => {
      axios.get('/api/auth/verify-token', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
          if(response.status === 200) {
            resolve(response.data.data)
          } else {
            console.log('ERROR', response.data.data)
            resolve(false)
          }
        })
        .catch((error) => {
          console.log('ERROR::', error)
          resolve(false)
        })
    })
  },
  generateAccessToken() {
    return new Promise((resolve, reject) => {
      axios.post('/api/auth/generate-token', { apikey: process.env.VUE_APP_AUTHKEY }).then(response => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else {
          resolve(false)
        }
      })
        .catch(error => {
          //console.log('ERROR::', error)
          reject(error)
        })
    })
  },
  updateCurrFile(e) {
    if (e.target.files && e.target.files[0]) {
      return e.target.files[0];
    } else {
      return ''
    }
  },
  capitalizeFirstLetter(string) {
    if(string === '' || string === null) {
      return string
    } else {
      let fstring = string.toLowerCase()
      return fstring.charAt(0).toUpperCase() + fstring.slice(1);
    }
  },
  getHourMinuteFromTime(time) {
    let now = this.dateOnlyFormat(new Date())
    var date = new Date(parseInt(time));
    console.log(now)
    return date.toLocaleTimeString('en', {
      hour: '2-digit',
      minute:'2-digit'
    });
  },
  emptyLocalStorage () {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
      
    // Remove userData from localStorage
    localStorage.removeItem("userData");
  },
  buildRequiredMessage(field) {
    return `Le champ ${field} est obligatoire`
  },
  isFile(variable) {
    return variable instanceof File;
  },
  resetObjectValues(obj) {
    for (let prop in obj) {
      if(prop == 'file') {
        obj[prop] = null;
      } else if(typeof prop == "number") {
        obj[prop] = 0;
      } else if(typeof prop == "string") {
        obj[prop] = "";
      } else if(typeof prop == "array") {
        obj[prop] = [];
      } else if(typeof prop == "object") {
        obj[prop] = {};
      } else {
        obj[prop] = null;
      }
    }
  },
}

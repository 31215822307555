export default [{
    path: '/apps/test',
    name: 'apps-test',
    component: () => import('@/views/test/main.vue'),
    meta: {
      pageTitle: 'ZONE DE TESTS',
      breadcrumb: [{
        text: 'Espace Tests',
        active: true,
      }],
    },
  },
  {
    path: '/apps/sample',
    name: 'apps-sample',
    component: () => import('@/views/sample/main.vue'),
    meta: {
      pageTitle: 'View Sample',
      breadcrumb: [{
        text: 'Espace Sample',
        active: true,
      }],
    },
  },
  {
    path: '/apps/category',
    name: 'apps-category',
    component: () => import('@/views/apps/category/main.vue'),
    meta: {
      pageTitle: 'Gestion des categories',
      breadcrumb: [{
        text: 'Categories',
        active: true,
      }],
    },
  },
  {
    path: '/apps/provider',
    name: 'apps-provider',
    component: () => import('@/views/apps/fournisseur/main.vue'),
    meta: {
      pageTitle: 'Gestion des fournisseurs',
      breadcrumb: [{
        text: 'Fournisseurs',
        active: true,
      }],
    },
  },
  {
    path: '/apps/post',
    name: 'apps-post',
    component: () => import('@/views/apps/post/main.vue'),
    meta: {
      pageTitle: 'Gestion des news',
      breadcrumb: [{
        text: 'Actualités',
        active: true,
      }],
    },
  },
  {
    path: '/apps/client',
    name: 'apps-client',
    component: () => import('@/views/apps/client/main.vue'),
    meta: {
      pageTitle: 'Gestion des utilisateurs',
      breadcrumb: [{
        text: 'Utilisateurs',
        active: true,
      }],
    },
  },
  {
    path: '/apps/product',
    name: 'apps-product',
    component: () => import('@/views/apps/product/main.vue'),
    meta: {
      pageTitle: 'Gestion des produits',
      breadcrumb: [{
        text: 'Produits',
        active: true,
      }],
    },
  },
  // PACK
  {
    path: '/apps/pack',
    name: 'apps-pack',
    component: () => import('@/views/apps/pack/main.vue'),
    meta: {
      pageTitle: 'Gestion des packs',
      breadcrumb: [{
        text: 'Packs',
        active: true,
      }],
    },
  },

  // SOINS
  {
    path: '/apps/soin',
    name: 'apps-soin',
    component: () => import('@/views/apps/soin/main.vue'),
    meta: {
      pageTitle: 'Gestion des soins',
      breadcrumb: [{
        text: 'Soins',
        active: true,
      }],
    },
  },

  // USER
  {
    path: '/apps/user',
    name: 'apps-user',
    component: () => import('@/views/apps/user/main.vue'),
    meta: {
      pageTitle: 'Gestion des administrateurs',
      breadcrumb: [{
        text: 'Administrateurs',
        active: true,
      }],
    },
  },
  {
    path: '/apps/user/save/:id?',
    name: 'apps-user-save',
    component: () => import('@/views/apps/user/save.vue'),
    meta: {
      pageTitle: 'Enregistrer administrateurs',
      breadcrumb: [{
        text: 'Administrateurs',
        active: true,
      }],
      navActiveLink: 'apps-user'
    },
  },
]